<template>
  <div class="cv_button">
    <el-form-item>
      <el-button type="primary" @click="$emit('copyInfo', null)" v-if="button_status"
        >复制</el-button
      >
      <el-button type="primary" @click="pasteInfo" v-else>粘贴</el-button>
    </el-form-item>
    <el-form-item class="mgr_left">
      <el-button type="primary" @click="pasteInfo" v-if="button_status">粘贴</el-button>
    </el-form-item>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    button_status: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},

  methods: {
    // 复制信息
    CopyInfo(str) {
      // 接受父组件传值，进行复制
      let _me = this;
      if (typeof str == "object") {
        // 用字符串传进
        let string = JSON.stringify(str);
        if (_me.$common.copyText(string)) {
          _me.$message.success("复制成功");
        } else {
          _me.$message.error("复制失败");
        }
      } else {
        _me.$message.error("复制数据失败");
      }
    },
    // 粘贴信息
    pasteInfo() {
      navigator.clipboard.readText().then((v) => {
        //  粘贴板的数据避免通过json解析后是字符串，先进行在此引用字符串
        let is_object = JSON.stringify(v);
        // 检测字符串有无\判断是否是对象，一般对象转成字符串再转成字符串第二个就是\
        if (is_object.indexOf("\\") == 2) {
          let object = JSON.parse(JSON.parse(is_object));
          // 触发父组件的粘贴，进行传值
          this.$emit("pasteInfo", object);
          //   this.edit_model = b;
        } else {
          this.$message.error("粘贴板的内容不符合当前列表信息");
        }
      });
    },
  },
};
</script>
<style scoped>
.cv_button {
    display: flex;
}

.cv_button .mgr_left {
    margin-left: -30px;
}
</style>
